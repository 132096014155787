import React from "react";
import { DatePicker, Input, Select, Radio, Space, Switch } from "antd";
import "./CustomFormFields.css";

export const FormInputField = ({ ...props }) => {
  return (
    <Input
      className="!border !border-[#ADBCC8] !rounded-md !shadow-none !bg-[#f6fafd] !text-[#000]"
      value={props?.value}
      name={props?.name}
      onChange={props?.onChange}
      placeholder={props?.placeholder}
      disabled={props?.disabled}
    />
  );
};

export const FormTextAreaField = ({ ...props }) => {
  const { TextArea } = Input;
  return (
    <TextArea
      rows={props?.rows}
      className="!border !border-[#ADBCC8] !rounded-md !shadow-none !bg-[#f6fafd]"
      value={props?.value}
      name={props?.name}
      onChange={props?.onChange}
      placeholder={props?.placeholder}
    />
  );
};

export const FormSelectField = ({ ...props }) => {
  return (
    <Select
      className="!w-full formSelectField"
      mode={props.mode}
      showSearch={props.showSearch}
      placeholder={props?.placeholder}
      allowClear={
        props?.allowClear === true || props?.allowClear === false
          ? props?.allowClear
          : true
      }
      value={props?.value}
      name={props?.name}
      onChange={props?.onChange}
      notFoundContent={props?.notFoundContent}
      options={props?.options}
      filterOption={(input, option) => option?.label.includes(input)}
      tagRender={props?.tagRender}
    />
  );
};

export const FormDatePickerField = ({ ...props }) => {
  return (
    <DatePicker
      className="!w-full !border !border-[#ADBCC8] !rounded-md !shadow-none !bg-[#f6fafd] !p-[10px]"
      placeholder={props?.placeholder}
      name={props?.name}
      value={props?.value || null}
      onChange={props?.onChange}
    />
  );
};

export const FormRadioGroup = ({ ...props }) => {
  return (
    <Radio.Group onChange={props?.onChange} value={props?.value}>
      <Space direction="vertical">
        {props?.options.map((option, index) => (
          <Radio key={index} value={option.value}>
            <span className="text-[#000] font-bold text-sm">
              {option.label}
            </span>
          </Radio>
        ))}
      </Space>
    </Radio.Group>
  );
};

export const FormSwitch = ({ ...props }) => {
  return (
    <Switch
      name={props?.name}
      checked={props?.value}
      onChange={props?.onChange}
      className="bg-[#00000040]"
      disabled={props?.disabled}
    />
  );
};

// How to use
//   const [memberType, setMemberType] = useState("Employment");

//   const handleRadio = (e) => {
//     setMemberType(e.target.value);
//   };
//   const MemberTypeOption = [
//     { label: "Employment", value: "Employment" },
//     { label: "Contractor", value: "Contractor" },
//   ];

// <FormInputField placeholder="Email of the team member/contractor" />
//           <FormSelectField placeholder="Select role" />
//           <FormSelectField mode="tags" placeholder="Select role" />
//           <FormDatePickerField placeholder="Select date" />
//           <FormTextAreaField rows={4} placeholder="Start typing reason here" />
//           <FormRadioGroup
//             options={MemberTypeOption}
//             onChange={handleRadio}
//             value={memberType}
//           />

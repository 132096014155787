import React, { useMemo } from "react";
import ReactApexChart from "react-apexcharts";

const ApexChart = ({ getCheckChart }) => {
  const series = useMemo(() => getCheckChart, [getCheckChart]);

  const options = useMemo(
    () => ({
      labels: ["Passing ", "Due", "Critical", "Failing"],
      colors: ["#8ACD56", "#4091F7", "#F6DB4E", "#EC5C56"],
      chart: {
        width: 330,
        type: "donut",
      },
      plotOptions: {
        pie: {
          donut: {
            size: "88%",
            labels: {
              show: true,
              name: {
                fontSize: "14px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                color: undefined,
                offsetY: -8,
              },
              value: {
                fontSize: "16px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 400,
                color: undefined,
                offsetY: 5,
                formatter: function (val) {
                  return val + "%";
                },
              },
            },
          },
          expandOnClick: false,
          dataLabels: {
            enabled: false,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        value: { top: "0px !important " },
        formatter: function (val, opts) {
          return opts.w.globals.series[opts.seriesIndex] + " " + val;
        },
      },
      annotations: {
        points: [
          {
            x: "50%",
            y: "50%",
            marker: {
              size: 0,
            },
            label: {
              text: "50%",
              style: {
                fontSize: "20px",
                color: "#000000",
              },
            },
          },
        ],
      },
    }),
    []
  );

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          key={JSON.stringify(series)} // Use series data as key
          options={options}
          series={series}
          type="donut"
          width={280}
        />
      </div>
    </div>
  );
};

export default ApexChart;

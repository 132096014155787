import { message } from "antd";
import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import NotFoundData from "../components/NotFoundData";
import {
  baseUrl,
  getAdminProfile,
  getProfile,
} from "../services/services.profile";
import { useUserContext } from "../contexts/userContext";

const ConnectionsOnboarding = () => {
  const { user, RoleManage, userData } = useUserContext();
  const [removeAccess, setRemoveAccess] = useState(
    user === RoleManage.ADMIN && userData.current_version === "AUTODESK"
      ? [RoleManage?.ADMIN]
      : [RoleManage?.MLRO]
  );
  const [profile, setProfile] = useState({});
  const [onBoarding, setOnBoarding] = useState([]);

  const getOnBoardingApi = () => {
    axios.get(baseUrl + "/api/onboarding/list").then((res) => {
      let array = res?.data?.data;
      array = res?.data?.data?.filter((item) => item?.status !== "confirmed");
      setOnBoarding(array);
    });
  };

  const onBoardingLink = (item) => {
    const onBoardingbaseUrl = "https://clients.onboarding.ipass.id";
    window.open(onBoardingbaseUrl + "/#/" + item?.token_id, "_blank");
  };

  const removeOnboarding = async (item) => {
    const res = await axios
      .post(baseUrl + `/user/onboarding/delete`, {
        tokenId: item?.token_id,
      })
      .then((res) => {
        message.success("Onboarding remove Successfully");
        getOnBoardingApi();
      })
      .catch((err) => {
        message.error("Invalid Onboarding request");
      });
  };

  const sendReminderApi = async (item) => {
    const res = await axios
      .post(baseUrl + `/user/onboarding/resendemail`, {
        tokenId: item?.token_id,
      })
      .then((res) => {
        message.success("Email has been sent Successfully");
        getOnBoardingApi();
      })
      .catch((err) => {
        message.error("Invalid email");
      });
  };

  useEffect(() => {
    // Get current login user admin data
    getAdminProfile().then((adminRes) => {
      if (adminRes?.data) {
        setProfile(adminRes?.data);
        getOnBoardingApi();
      }
    });
    // getProfile()
    //   .then((res) => {
    //     setProfile(res);
    //     getOnBoardingApi();
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }, []);

  return (
    <>
      {onBoarding.length === 0 ? (
        <>
          <NotFoundData message="No onboardings are currently taking place" />
        </>
      ) : (
        <>
          {onBoarding.map((item, i) => {
            return (
              <>
                {/* <div className="sm:flex w-full gap-4 mb-6" key={i}> */}
                {/* <div className="2xl:w-[12%] xl:w-[15%] md:w-[20%] sm:w-[25%] w-full flex justify-center items-center mb-4 md:mb-0">
                  <div className="h-32 w-32">
                    <img
                      className="h-full w-full object-cover rounded-full"
                      src={baseUrl + "/" + item?.brand_avatar}
                      alt=""
                    />
                  </div>
                  </div> */}
                {/* <div className="2xl:w-[88%] xl:w-[85%] md:w-[80%] sm:w-[75%] flex-col md:flex-row bg-[#f0f6fd] rounded-md p-4 flex justify-between gap-4"> */}
                <div
                  className="w-full mb-6 flex-col sm:flex-row bg-[#f0f6fd] rounded-md p-4 flex justify-between gap-4"
                  key={i}
                >
                  <div className="flex flex-col text-[15px] justify-center items-start p-3">
                    <p className="font-bold mb-4">{item?.user_info?.email}</p>
                    <p
                      className="underline text-primary hover:cursor-pointer break-all mb-1"
                      onClick={() => {
                        onBoardingLink(item);
                      }}
                    >
                      {item?.receivedInfo?.respResult?.body?.split(
                        "Your artpass ID KYC onboarding link:"
                      )}
                      {/* Onboarding link */}
                    </p>
                    <div className="flex gap-1">
                      <p>requested on:</p>
                      <span className="font-semibold">
                        {item?.receivedInfo?.respResult?.date_created
                          ?.toString()
                          .slice(0, 16)}
                      </span>
                    </div>
                    <div className="flex gap-1 mb-4">
                      <p>Status:</p>
                      <span className="font-semibold">{item?.status}</span>
                    </div>
                    {/* {item?.status === "confirmed" ? (
                      <p className="mb-[3px]">
                        Status :{" "}
                        <span className="font-semibold">Onboarding</span>
                      </p>
                    ) : (
                      <p className="mb-[3px]">
                        Status :{" "}
                        <span className="font-semibold">
                          Verification Failed
                        </span>
                      </p>
                    )}
                    <p>
                      Stage :
                      <span className="font-bold">
                        {"  "}
                        {item?.status} it failed
                      </span>
                    </p> */}
                  </div>

                  <div className="flex flex-col justify-center gap-3 items-center text-[15px] px-5">
                    {/* Send reminder button  */}
                    <button
                      className="border border-[#86c1f4] text-primary px-3 whitespace-nowrap h-9 w-full  rounded-lg"
                      onClick={() => sendReminderApi(item)}
                    >
                      Send reminder
                    </button>

                    {/* Remove onboarding button  */}
                    {removeAccess.includes(user) && (
                      <button
                        className="border border-[#86c1f4] text-primary px-3 whitespace-nowrap h-9 w-full  rounded-lg"
                        onClick={() => removeOnboarding(item)}
                      >
                        Remove onboarding
                      </button>
                    )}
                  </div>
                </div>
                {/* </div> */}
              </>
            );
          })}
        </>
      )}
    </>
  );
};

export default ConnectionsOnboarding;
